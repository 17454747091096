import React, { PropsWithChildren, useMemo, useState } from "react";

export interface LayoutContextInterface {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

export const LayoutContext = React.createContext<LayoutContextInterface>({
  collapsed: false,
  setCollapsed: () => {},
});

const LayoutProvider = ({ children }: PropsWithChildren) => {
  const [collapsed, setCollapsed] = useState(false);
  const value = useMemo(() => ({ collapsed, setCollapsed: setCollapsed }), [collapsed]);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export default LayoutProvider;
