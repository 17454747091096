/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";

// ==============================|| LOADER ||============================== //
const Loader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [progress]);

  useEffect(() => {
    setProgress(1);
  }, []);

  return (
    <div className={`fixed top-0 h-[2px] bg-[#3594D0]`} style={{ width: `${progress}%` }}>
      <div className="h-1 bg-primary-400" style={{ width: "50%" }}></div>
    </div>
  );
};

export default Loader;
