import NotFound from "../../pages/NotFound";
import { lazy } from "react";
import {  Redirect, Route, Switch } from "react-router-dom";

const ProjectPage = lazy(() => import("../../pages/project"));
const DetailProjectPage = lazy(() => import("../../pages/project/detail"));
const LoginPage = lazy(() => import("../../pages/login"));
const RedirectPage = lazy(() => import("../../pages/redirectPage"));
const ForgotPasswordPage = lazy(() => import("../../pages/forgot-password"));
const ResetPasswordPage = lazy(() => import("../../pages/reset-password"));
const AuthorityManagement = lazy(() => import("../../pages/authority-management"));
const AuthorityCreate = lazy(() => import("../../pages/authority-management/create"));
const AuthorityEdit = lazy(() => import("../../pages/authority-management/edit"));
const ChangePassword = lazy(() => import("../../pages/change-password"));

export const RouteConfigPrivate= () => {
  return (
    <Switch>
      {/* Redirect from "/" to "/project" */}
      <Route exact path="/">
        <Redirect to="/project/?pageSize=10&pageCurrent=1" />
      </Route>

      <Route exact path="/project" component={ProjectPage} />
      <Route path="/project/:id" component={DetailProjectPage} />

      <Route exact path="/authority-management" component={AuthorityManagement} />
      <Route path="/authority-management/create" component={AuthorityCreate} />
      <Route path="/authority-management/edit/:id" component={AuthorityEdit} />

      <Route path="/change-password" component={ChangePassword} />

      {/* NotFound Route */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
  //  private: [
  //   {
  //     path: "/",
  //     element: <Navigate to="/project/?pageSize=10&pageCurrent=1" />,
  //   },
  //   {
  //     path: "/project",
  //     element: <ProjectPage />,
  //   },
  //   {
  //     path: "/project/:id",
  //     element: <DetailProjectPage />,
  //   },
  //   {
  //     path: "authority-management",
  //     element: <AuthorityManagement />,
  //   },
  //   {
  //     path: "authority-management/create",
  //     element: <AuthorityCreate />,
  //   },
  //   {
  //     path: "authority-management/edit/:id",
  //     element: <AuthorityEdit />,
  //   },
  //   {
  //     path: "/change-password",
  //     element: <ChangePassword />,
  //   },
  //   {
  //     path: "*",
  //     element: <NotFound />,
  //   },
  // ],
};

export const RouteConfigPublic= () => {
  return (
    <Switch>
      {/* Redirect from "/" to "/project" */}
      
      <Route path="/login" component={LoginPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />

  

      <Route path="/reset-password" component={ResetPasswordPage} />

      {/* NotFound Route */}
      <Route path="*" component={RedirectPage} />
    </Switch>
  );
  // public: [
  //   { path: "/login", element: <LoginPage /> },
  //   { path: "/forgot-password", element: <ForgotPasswordPage /> },
  //   { path: "/reset-password", element: <ResetPasswordPage /> },
  //   {
  //     path: "*",
  //     element: <RedirectPage />,
  //   },
  // ],
};