import { api } from "../api";

export type getPermissionsResponse = {
  id: number;
  name: string;
  key: string;
}[];

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.query<getPermissionsResponse, void>({
      query: () => ({
        url: "/permissions",
        method: "GET",
      }),
    }),
  }),
});

export { injectedRtkApi as ContentApi };
export const { useGetPermissionsQuery } = injectedRtkApi;
