import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionType } from "../../types";

export interface PermissionSliceType {
  permissions: OptionType[];
}

const initialState: PermissionSliceType = {
  permissions: [],
};

const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    updatePermission: (state, action: PayloadAction<PermissionSliceType>) => {
      state.permissions = action.payload.permissions;
    },
  },
});

export const { updatePermission } = permissionSlice.actions;

const permissionsReducer = permissionSlice.reducer;

export default permissionsReducer;
