import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { TypeUser } from "../endpoints/auth";

export interface AuthSliceShape {
  accessToken?: string | null;
  user?: TypeUser | undefined | null;
}

const initialState: AuthSliceShape = {};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    updateUserProfile: (state, action: PayloadAction<TypeUser | undefined | null>) => {
      state.user = action.payload;
    },
  },
});
export const { updateAccessToken, updateUserProfile } = authSlice.actions;

export default authSlice.reducer;
