import axios from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { BaseQueryApi } from "@reduxjs/toolkit/query/react";
import { createApi } from "@reduxjs/toolkit/query/react";
import type { AxiosError, AxiosRequestConfig } from "axios";
import { REHYDRATE } from "redux-persist";
import { updateAccessToken, updateUserProfile } from "./slices/auth.slice";
import { store, type RootState } from "./store";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */


const UN_AUTHORIZED_STATUS = 401;

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      body?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    BaseQueryApi,
    unknown
  > =>
    async ({ url, method, body, params, headers }, { getState, signal }) => {
      try {
        const store = getState() as RootState;

        const getAuthHeader = () => {
          return {
            Authorization: `Bearer ${store?.auth?.accessToken}`,
          };
        };

        const result = await axios({
          url: url.startsWith("http") ? url : (baseUrl || "") + url,
          method: method ?? "GET",
          data: body,
          timeout: typeof window === "undefined" ? 30000 : 600000,
          params,
          ...(typeof window === "undefined"
            ? {
              validateStatus: () => {
                return true;
              },
            }
            : {}),
          signal,
          headers: {
            ...getAuthHeader(),
            ...headers,
          },
        });

        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError as AxiosError;

        if (err?.response?.status === UN_AUTHORIZED_STATUS) {
          store.dispatch(updateAccessToken(null));
          store.dispatch(updateUserProfile(null));
          localStorage.clear();

        }

        return {
          error: {
            status: err.response?.status ?? null,
            data: err.response?.data || err.message,
          },
        };
      }
    };

export const api = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_PUBLIC_API_BASE_URL as string,
  }),
  tagTypes: ['project'],
  extractRehydrationInfo(action: any, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }

    return undefined;
  },
  endpoints: () => ({}),
});
