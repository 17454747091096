import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { api } from "./api";
import authSlice from "./slices/auth.slice";
import storage from "redux-persist/lib/storage";
import permissionsReducer from "./slices/permission.slice";

// import storesSlice from './slices/stores.slice';

const rootReducer = combineReducers({
  auth: authSlice,
  // store: storesSlice,
  permissions: permissionsReducer,
  [api.reducerPath]: api.reducer,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "store"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
