import { ErrorResponseApiType } from "../../types";
import { api } from "../api";

/* eslint-disable @typescript-eslint/no-explicit-any */

export type PostLoginResponse = LoginResponse | ErrorResponseApiType;
export type LoginResponse = {
  access_token?: string | null;
  refresh_token?: string | null;
};
export type PostLoginApiArg = {
  loginBody: {
    email: string;
    password: string;
  };
};

export type PostForgotPasswordResponse = ForgotPasswordResponse | ErrorResponseApiType;
export type ForgotPasswordResponse = any;
export type PostForgotPasswordApiArg = {
  forgotPasswordBody: {
    email: string;
  };
};

export type TypeUser = {
  canSync?: boolean;
  companyName?: string;
  id?: number;
  name?: string;
  role?: string;
  twoFactorMethod?: string;
  isVerified?: boolean;
  uuid?: string;
  deletedByAdmin?: boolean;
  deletedFlg?: boolean;
  createdAt?: string;
  updatedAt?: string | undefined | null;
  deletedAt?: string | undefined | null;
  lastActive?: string;
  isVerifiedAt?: string;
  prefersEmailId?: number;
  prefersEmail?: {
    deletedFlg?: boolean;
    email?: string;
    emailSafe?: string;
    id?: number;
    updatedAt?: string;
  };
  email?: string;
  stores?: any;
  userParents?: any;
  userRole?: {
    id?: number;
    name?: string;
  };
  permission?: {
    id: number;
    key: string;
    name: string;
  };
};

export type GetUserMeApiResponse = {
  data: TypeUser | undefined | null;
  status: string;
};
export type GetUserMeApiArg = void;

export type PatchResetPasswordResponse = /** status 200 Success */ {
  data?: any;
  status?: string;
};

export type PatchResetPasswordApiArg = {
  resetPasswordBody: {
    token: string;
    password: string;
  };
};


export type PutChangePasswordApiResponse = /** status 200 Success */ {
  status?: string;
};
export type PutChangePasswordApiArg = {
  dataChangePassword: {
    password?: string;
    passwordConfirm?: string;
    newPassword?: string;
  };
};

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postLogin: build.mutation<PostLoginResponse, PostLoginApiArg>({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: "POST",
        body: queryArg.loginBody,
      }),
    }),

    postLogout: build.mutation({
      query: () => ({
        url: `/auth/logout`,
        method: "POST",
      }),
    }),
    postForgotPassword: build.mutation<PostForgotPasswordResponse, PostForgotPasswordApiArg>({
      query: (queryArg) => ({
        url: `/auth/forgot_password`,
        method: "POST",
        body: queryArg.forgotPasswordBody,
      }),
    }),
    patchResetPassword: build.mutation<PatchResetPasswordResponse, PatchResetPasswordApiArg>({
      query: (queryArg) => ({
        url: `/auth/reset-password`,
        method: "PATCH",
        body: queryArg.resetPasswordBody,
      }),
    }),
    getUserMe: build.query<GetUserMeApiResponse, GetUserMeApiArg>({
      query: () => ({ url: "/me" }),
    }),
    putChangePassword: build.mutation<PutChangePasswordApiResponse, PutChangePasswordApiArg>({
      query: (queryArg) => ({
        url: `/me/change-password`,
        method: 'PUT',
        body: queryArg.dataChangePassword,
      }),
    }),
  }),
});

export { injectedRtkApi as ContentApi };

export const {
  usePostLoginMutation,
  usePostLogoutMutation,
  usePatchResetPasswordMutation,
  usePostForgotPasswordMutation,
  useGetUserMeQuery,
  useLazyGetUserMeQuery,
  usePutChangePasswordMutation,

} = injectedRtkApi;
