import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { Spin as Spinner } from "antd";

function SpinBase({
  spinning,
  theme,
  tip,
}: {
  spinning?: boolean;
  theme?: 'black' | 'white';
  tip?: React.ReactNode | string;
}) {
  return (
    <div>
      {spinning && (
        <>
          <div
            aria-label="advertisement"
            className={`${
              theme === 'black' ? 'bg-[#111111] bg-opacity-30' : 'bg-[#fff] bg-opacity-50'
            }  fixed top-0 bottom-0 left-0 right-0 z-[1010]`}
            // onClick={() => setOpenAdvertisement(false)}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          />
          <div className="fixed top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex flex-col z-[2000] items-center gap-[10px]">
            <Spinner indicator={<LoadingOutlined style={{ fontSize: 40, color: '#009C75' }}  />} />
            <div>{tip}</div>
          </div>
        </>
      )}
    </div>
  );
}
SpinBase.defaultProps = {
  spinning: true,
  theme: 'white',
};
export default SpinBase;
