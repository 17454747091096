import { useEffect } from "react";
import { useGetPermissionsQuery } from "../redux/endpoints/permissions";
import { useDispatch } from "react-redux";
import { OptionType } from "../types";
import { updatePermission } from "../redux/slices/permission.slice";

function usePermissions() {
  const { data } = useGetPermissionsQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    let permissions: OptionType[] = [];

    if (data) {
      permissions = data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
    }

    dispatch(updatePermission({ permissions }));
  }, [data]);
}

export default usePermissions;
